// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e3259bd5-358f-4b62-b6d4-5ecc6665a3d9",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "d2427b19f21b4cd195acfa23eed7a0f7",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
